'use client'

import React, { useEffect, useState, Suspense } from 'react'
import { usePathname, useRouter } from 'next/navigation'
import Link from 'next/link'
import { Icon } from '@iconify/react'

// -------------
// REACT-QUERY / HOOKS
// -------------
import { useQueryTC, useMutationTC, useSWRQueryTC } from '@/hooks/react-query'
import { useOrganizations } from '@/hooks/req'

// -------------
// COMPONENTS & UTILS
// -------------
import ModalContext from '@/app/context'
import FeedbackRating from '@/components/feedback-rating'
import ModalForOrganization from '@/components/ModalForOrganization'
import ModalForProject from '@/components/ModalForProject'
import ModalForWelcome from '@/components/ModalForWelcome'
import NotificationsCard from '@/components/notifications-card'
// import { Badge } from '@/components/NotificationBadge'
import { Avatar } from '@/components/Avatar'
import { Badge as Chip } from '@/components/ui/badge'
import { Textarea } from '@heroui/input'
import { Popover, PopoverTrigger, PopoverContent } from '@heroui/popover'
import { Separator as Divider } from '@/components/ui/separator'
import { LoadingIndicator } from '@/components/LoadingIndicator'
import { useDisclosure } from '@heroui/react'
import Logo from '@/images/logo.svg'

import {
  DropdownMenu as DropdownUI,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'

// -------------
// PRISMA TYPES
// -------------
import { Organization, Project, User } from '@prisma/client'

// -------------
// SIDEBAR BOILERPLATE
// -------------
import type { SidebarItem } from '@/components/sidebar/sidebar'
import Sidebar from '@/components/sidebar/sidebar'
import SidebarDrawer from '@/components/sidebar/sidebar-drawer'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  ScrollShadow,
  Spacer,
} from '@heroui/react'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'

// -------------
// STUB OR REPLACE
// -------------
function logErrorToMyService(error: any, errorInfo: any) {
  console.error('[ErrorBoundary] error:', error, errorInfo)
}

// -------------
// ERROR BOUNDARY
// -------------
class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean }
> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }
  static getDerivedStateFromError() {
    return { hasError: true }
  }
  componentDidCatch(error, errorInfo) {
    logErrorToMyService(error, errorInfo)
  }
  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>
    }
    return this.props.children
  }
}

// import {Chip} from "@heroui/react";
// import {Icon} from "@iconify/react";

import { SidebarItemType } from '../../components/sidebar/sidebar'
// import TeamAvatar from "./team-avatar";

export const sectionItems: SidebarItem[] = [
  {
    key: 'project',
    title: 'Project',
    items: [
      {
        key: 'overview',
        href: '/organizationSlug/projectSlug',
        icon: 'solar:chart-outline',
        title: 'Overview',
      },
      {
        key: 'alerts',
        href: '/organizationSlug/projectSlug/alerts',
        icon: 'solar:bell-outline',
        title: 'Alerts',
        // endContent: (
        //   <Icon
        //     className="text-default-400"
        //     icon="solar:add-circle-line-duotone"
        //     width={24}
        //   />
        // ),
      },
      {
        key: 'source-maps',
        href: '/organizationSlug/projectSlug/source-maps',
        icon: 'solar:code-linear',
        title: 'Source Maps',
      },
      {
        key: 'api-keys',
        href: '/organizationSlug/projectSlug/api-keys',
        icon: 'solar:key-outline',
        title: 'API Keys',
      },
      {
        key: 'settings',
        href: '/organizationSlug/projectSlug/settings',
        icon: 'solar:settings-outline',
        title: 'Settings',
      },
    ],
  },
  {
    key: 'organization',
    title: 'Organization Settings',
    items: [
      {
        key: 'projects',
        href: '/organizationSlug',
        icon: 'solar:folder-outline',
        title: 'Projects',
      },
      {
        key: 'integrations',
        href: '/organizationSlug/integrations',
        icon: 'solar:plug-circle-outline',
        title: 'Integrations',
      },
      {
        key: 'teammates',
        href: '/organizationSlug/teammates',
        icon: 'solar:users-group-rounded-outline',
        title: 'Teammates',
        // endContent: (
        //   <Chip size="sm" variant="flat">
        //     3
        //   </Chip>
        // ),
      },
      {
        key: 'teams',
        href: '/organizationSlug/teams',
        icon: 'solar:users-group-two-rounded-outline',
        title: 'Teams',
      },
      {
        key: 'settings',
        href: '/organizationSlug/settings',
        icon: 'solar:settings-outline',
        title: 'Settings',
      },
    ],
  },
]

type Props = {
  children: React.ReactNode
  organizationSlug: string
  projectSlug: string
}

export default function Component({
  children,
  params: { organizationSlug, projectSlug },
}: Props) {
  const router = useRouter()
  const pathname = usePathname()

  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure()

  const qLogout = useQueryTC('/api/v1/auth/logout', 'logout', false)
  const qUser = useSWRQueryTC<User>('/api/v1/user', 'user')
  const { organizations, refetch, isFetching } = useOrganizations()

  const mOrganization = useMutationTC<Partial<Project>>('/api/v1/organization')
  const mProjects = useMutationTC<any>('/api/v1/project')

  const [selectedOrganizationSlug, setSelectedOrganizationSlug] =
    useState(organizationSlug)
  const [selectedProjectSlug, setSelectedProjectSlug] = useState(projectSlug)

  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization | null>(null)
  const [selectedProject, setSelectedProject] = useState<Project | null>(null)

  const [menuOrganization, setMenuOrganization] = useState<Organization | null>(
    null
  )

  const [isOrganizationModalOpen, setOrganizationModalOpen] = useState(false)
  const [isProjectModalOpen, setProjectModalOpen] = useState(false)
  const [showDebugPanel, setShowDebugPanel] = useState(false)

  const [sidebarItems, setSidebarItems] = useState<SidebarItem[]>([
    ...sectionItems,
  ])

  useEffect(() => {
    const [g, o, p] = pathname.split('/')
    const s = sectionItems.map((section) => {
      if (!section || !section.items) return section
      return {
        ...section,
        items: section.items.map((item) => {
          if (!item || !item.href) return item
          return {
            ...item,
            href: item.href
              .replace('organizationSlug', o)
              .replace('projectSlug', p),
          }
        }),
      }
    })
    if (!p || sectionItems[1].items?.map((i) => i.key).includes(p)) {
      setSidebarItems([s[1]])
    } else {
      setSidebarItems([s[0]])
    }
  }, [pathname])

  useEffect(() => {
    if (!organizations) return
    const orgs = organizations
    if (orgs?.length) {
      const foundOrg =
        orgs.find((t) => t.slug === selectedOrganizationSlug) || orgs[0]
      setSelectedOrganization(foundOrg)
      setSelectedOrganizationSlug(foundOrg?.slug)

      if (foundOrg?.projects?.length) {
        const foundProject = foundOrg.projects.find(
          (p) => p.slug === selectedProjectSlug
        )
        setSelectedProject(foundProject || null)
        setSelectedProjectSlug(foundProject?.slug || null)
      } else {
        setSelectedProject(null)
      }

      if (!menuOrganization) {
        setMenuOrganization(foundOrg)
      }
    }
  }, [organizations, selectedOrganizationSlug, selectedProjectSlug])

  useEffect(() => {
    const parts = pathname.split('/')
    if (!organizations || !parts[1]) return
    const orgSlug = parts[1]
    const projSlug = parts[2]
    const matchOrg = organizations.find((o) => o.slug === orgSlug)
    if (matchOrg) {
      setSelectedOrganization(matchOrg)
      setSelectedOrganizationSlug(matchOrg.slug)
      if (projSlug) {
        const matchProj = matchOrg?.projects?.find((p) => p.slug === projSlug)
        if (matchProj) {
          setSelectedProject(matchProj)
          setSelectedProjectSlug(matchProj.slug)
        } else {
          setSelectedProject(null)
          setSelectedProjectSlug(null)
        }
      } else {
        setSelectedProject(null)
      }
    }
  }, [pathname, organizations])

  // -------------
  // HANDLERS
  // -------------
  const handleCreateOrganizationCallback = async (
    name: string,
    color: string,
    id?: string
  ) => {
    await mOrganization.mutateAsync({ name })
    await refetch()
  }

  const handleCreateProjectCallback = async (
    name: string,
    organizationId: string
  ) => {
    await mProjects.mutateAsync({ name, organizationId })
    await refetch()
  }

  const handleLogOut = async () => {
    await qLogout.refetch()
    window.location.href = '/'
  }

  // For showing/hiding organization projects in the dropdown
  let timer: ReturnType<typeof setTimeout> | null = null
  const handleSetMenuOrganizationEnter = (organization: Organization) => {
    timer = setTimeout(() => {
      setMenuOrganization(organization)
    }, 100)
  }
  const handleSetMenuOrganizationLeave = () => {
    if (timer) clearTimeout(timer)
  }

  // Crash testers
  const someFunctionWithABiggerName = () => {
    const aNumber = 1
    const bNumber = 0
    const cNumber = aNumber / bNumber
    throw Error('Attempt to divide by zero!')
  }
  const someFunctionWithABigName = () => {
    someFunctionWithABiggerName()
  }

  // If user is brand-new
  if (qUser?.data?.shouldOnboard) {
    return (
      <div className="relative bg-gray-500">
        <ModalForWelcome
          open={true}
          setOpen={() => qUser.refetch()}
          userId={qUser?.data?.user?.id}
        />
      </div>
    )
  }
  const content = (): JSX.Element => {
    return (
      <div className="relative flex w-72 flex-col border-r-small border-divider">
        <div className="flex items-center justify-between w-full">
          <div className="relative flex items-center gap-2 border-b border-gray-200 w-full">
            <Dropdown showArrow backdrop="blur">
              <DropdownTrigger>
                <Button
                  variant="light"
                  className="w-full justify-between h-16 !px-4 !py-4 rounded-none group"
                >
                  <div>
                    {selectedOrganization ? (
                      <div className="flex items-center gap-3">
                        <Avatar
                          className="h-10 w-10 rounded-lg border border-gray-200"
                          organization={selectedOrganization}
                        />
                        <div className="">
                          <div className="flex items-center gap-1.5">
                            <div className="text-left font-medium">
                              {selectedOrganization.name}
                            </div>
                            <Icon
                              className="text-default-400 h-3 w-3"
                              icon="lucide:refresh-cw"
                            />
                          </div>
                          <div className="text-left text-gray-600">
                            Free Plan
                          </div>
                        </div>
                      </div>
                    ) : isFetching ? (
                      <LoadingIndicator />
                    ) : (
                      'Select Organization'
                    )}
                  </div>
                </Button>
              </DropdownTrigger>
              <DropdownMenu aria-label="Organizations" variant="flat">
                <DropdownSection showDivider>
                  {organizations?.map((o) => (
                    <DropdownItem
                      key={o.slug}
                      textValue={o.name}
                      onMouseEnter={() => handleSetMenuOrganizationEnter(o)}
                      onMouseLeave={handleSetMenuOrganizationLeave}
                      onPress={() => {
                        router.push(`/${o.slug}`)
                        onClose()
                      }}
                    >
                      <div className="flex items-center gap-2 w-56">
                        <Avatar className="h-6 w-6" organization={o} />
                        <div className="flex items-center gap-1.5">
                          {o.name}
                          <Chip
                            size="sm"
                            className="bg-gradient-to-br from-[#FFB457] to-[#FF705B]"
                          >
                            Pro
                          </Chip>
                        </div>
                      </div>
                    </DropdownItem>
                  ))}
                </DropdownSection>
                <DropdownSection>
                  <DropdownItem
                    key="create-org"
                    startContent={
                      <Icon icon="solar:add-circle-linear" width={24} />
                    }
                    onPress={() => setOrganizationModalOpen(true)}
                  >
                    Create Organization
                  </DropdownItem>
                </DropdownSection>
              </DropdownMenu>
            </Dropdown>
            <div className="absolute z-10 top-0 right-0 h-full flex items-center gap-2 pr-4">
              <Link href={`/${organizationSlug}`}>
                <div className="h-9 w-9 hover:bg-gray-300 bg-white cursor-pointer flex items-center justify-center rounded-full">
                  <Icon
                    className="text-default-400 h-6 w-6"
                    icon="lucide:settings"
                  />
                </div>
              </Link>
            </div>
          </div>

          {/* 
              ──────────────────────────
              [OPTIONAL] NOTIFICATIONS
              ──────────────────────────
              If you want to keep the notifications button at the top, leave as-is.
              If you want to remove it, delete this entire .flex.
            */}
          {/* <div className="flex items-center justify-end gap-1">
              <Popover offset={12} placement="bottom-start">
                <PopoverTrigger>
                  <Button
                    disableRipple
                    isIconOnly
                    className="overflow-visible"
                    radius="full"
                    variant="light"
                  >
                    <Badge
                      color="danger"
                      content="5"
                      showOutline={false}
                      size="md"
                    >
                      <Icon
                        className="text-default-500"
                        icon="solar:bell-linear"
                        width={22}
                      />
                    </Badge>
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="max-w-[90vw] p-0 sm:max-w-[380px]">
                  <NotificationsCard className="w-full shadow-none" />
                </PopoverContent>
              </Popover>
            </div> */}
        </div>
        <Spacer y={4} />
        {true && (
          <div className="flex flex-col px-4">
            {/* Project dropdown under the selected org */}
            {menuOrganization?.projects && (
              <Dropdown showArrow className="w-full">
                <DropdownTrigger>
                  <Button
                    variant="light"
                    className="w-full justify-start rounded-md border border-gray-400 !px-3 !h-14"
                  >
                    {selectedProject ? (
                      <div className="flex items-center gap-2 !my-2">
                        <Avatar
                          className="h-9 w-9 rounded-lg border border-gray-200"
                          project={selectedProject}
                        />
                        <div className="">
                          <div
                            className="text-left text-gray-600 font-medium"
                            style={{ fontSize: '10px' }}
                          >
                            PROJECT
                          </div>
                          <div className="text-left -mt-1 mb-1">
                            {selectedProject.name}
                          </div>
                        </div>
                      </div>
                    ) : isFetching ? (
                      <LoadingIndicator />
                    ) : (
                      'Select Project'
                    )}
                    <Icon
                      className="text-default-400 ml-auto"
                      icon="lucide:chevrons-up-down"
                    />
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  aria-label="Projects"
                  variant="flat"
                  className="w-full"
                >
                  <DropdownSection showDivider className="w-full">
                    {menuOrganization.projects.length === 0 && (
                      <DropdownItem
                        key="no-projects"
                        className="opacity-50 w-56"
                      >
                        No projects!
                      </DropdownItem>
                    )}
                    {menuOrganization.projects.map((p) => (
                      <DropdownItem
                        key={p.slug}
                        textValue={p.name}
                        onPress={() => {
                          router.push(`/${menuOrganization.slug}/${p.slug}`)
                          onClose()
                        }}
                      >
                        <div className="flex items-center gap-2 w-56">
                          <Avatar className="h-6 w-6" project={p} />
                          {p.name}
                        </div>
                      </DropdownItem>
                    ))}
                  </DropdownSection>
                  <DropdownSection>
                    <DropdownItem
                      key="new-project"
                      startContent={
                        <Icon icon="solar:add-circle-linear" width={24} />
                      }
                      onPress={() => setProjectModalOpen(true)}
                    >
                      New Project
                    </DropdownItem>
                  </DropdownSection>
                </DropdownMenu>
              </Dropdown>
            )}
          </div>
        )}

        <ScrollShadow className="-mr-6 h-full max-h-full m-4">
          {/* SIDEBAR NAV */}
          <Sidebar
            defaultSelectedKey="home"
            iconClassName="group-data-[selected=true]:text-primary-foreground"
            itemClasses={{
              base: 'data-[selected=true]:bg-primary-400/20 dark:data-[selected=true]:bg-primary-300 data-[hover=true]:bg-default-300/20 dark:data-[hover=true]:bg-default-200/40',
              title: 'group-data-[selected=true]:text-primary-foreground',
            }}
            items={sidebarItems}
            onSelect={() => {
              onClose()
            }}
          />
          <Spacer y={8} />

          {/* "Upgrade to Pro" card (demo) */}
          {/* <Card className="mx-2 overflow-visible" shadow="sm">
              <CardBody className="items-center py-5 text-center">
                <h3 className="text-medium font-medium text-default-700">
                  Upgrade to Pro
                  <span aria-label="rocket-emoji" className="ml-2" role="img">
                    🚀
                  </span>
                </h3>
                <p className="p-4 mb-1 text-small text-default-500">
                  Get 1 month free and unlock all the features of the pro plan.
                </p>
              </CardBody>
              <CardFooter className="absolute -bottom-8 justify-center">
                <Button className="px-10" radius="full">
                  Upgrade
                </Button>
              </CardFooter>
            </Card> */}
        </ScrollShadow>
        <div className="m-4">
          <Dropdown>
            <DropdownTrigger>
              <Button className="bg-white w-full flex items-center gap-2 border rounded-md border-gray-400">
                <Avatar className="h-6 w-6" user={qUser?.data} />
                <span className="text-gray-950">
                  {qUser?.data?.firstName} {qUser?.data?.lastName}
                </span>
                <Icon
                  icon="material-symbols:more-horiz"
                  className="text-default-500 ml-auto"
                  width={24}
                />
              </Button>
            </DropdownTrigger>
            <DropdownMenu aria-label="User Actions" variant="flat">
              <DropdownSection showDivider aria-label="Profile & Actions">
                <DropdownItem
                  key="profile"
                  className="w-56"
                  onPress={() => router.push('/profile')}
                >
                  Profile
                </DropdownItem>
                <DropdownItem
                  key="settings"
                  onPress={() => router.push('/profile')}
                >
                  Account Security
                </DropdownItem>
                <DropdownItem
                  key="notifications"
                  onPress={() => router.push('/profile')}
                >
                  Notifications
                </DropdownItem>
              </DropdownSection>
              <DropdownSection>
                <DropdownItem
                  key="logout"
                  color="danger"
                  onPress={handleLogOut}
                >
                  Log Out
                </DropdownItem>
              </DropdownSection>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    )
  }
  return (
    <Suspense>
      {/* ORGANIZATION MODAL */}
      <ModalForOrganization
        open={isOrganizationModalOpen}
        setOpen={setOrganizationModalOpen}
        handleCallback={handleCreateOrganizationCallback}
      />
      {/* PROJECT MODAL */}
      <ModalForProject
        organization={selectedOrganization}
        organizations={organizations}
        open={isProjectModalOpen}
        setOpen={setProjectModalOpen}
        handleCallback={handleCreateProjectCallback}
      />
      <div className="flex h-full min-h-[48rem]">
        <div className="flex h-dvh min-w-24 w-full">
          <SidebarDrawer
            className=""
            isOpen={isOpen}
            onOpenChange={onOpenChange}
          >
            {content()}
          </SidebarDrawer>
          <div className="relative">
            <div className="absolute top-4 left-4">
              <Button
                isIconOnly
                className="flex lg:hidden"
                size="sm"
                variant="light"
                onPress={onOpen}
              >
                <Icon
                  className="text-default-500"
                  height={24}
                  icon="solar:hamburger-menu-outline"
                  width={24}
                />
              </Button>
            </div>
          </div>
          <div className="flex-1 flex flex-col h-screen">
            {process.env.NEXT_PUBLIC_IS_PROD !== '1' && !showDebugPanel && (
              <div className="absolute top-2 left-2 flex flex-col items-center justify-center gap-1 z-50">
                <div
                  className="w-2 h-2 bg-yellow-400 rounded-full cursor-pointer"
                  onClick={() => setShowDebugPanel(true)}
                ></div>
              </div>
            )}
            {process.env.NEXT_PUBLIC_IS_PROD !== '1' && showDebugPanel && (
              <div className="absolute top-2 left-2 flex flex-col gap-1 z-50 bg-white border rounded">
                <div
                  className="w-2 h-2 bg-yellow-400 rounded-full cursor-pointer"
                  onClick={() => setShowDebugPanel(false)}
                ></div>
                <ErrorBoundary>
                  <Button
                    size="sm"
                    variant="destructive"
                    onClick={() => {
                      const aboutTime: any = undefined
                      const backToTheFuture = aboutTime.backToTheFuture
                    }}
                  >
                    Crash Undefined Access
                  </Button>
                  <Button
                    size="sm"
                    variant="destructive"
                    onClick={() => {
                      throw Error('Crash!')
                    }}
                  >
                    Crash Throw Error
                  </Button>
                  <Button
                    size="sm"
                    variant="destructive"
                    onClick={() => {
                      // @ts-ignore
                      const rick = morty.uncle
                    }}
                  >
                    Crash Undefined Variable
                  </Button>
                  <Button
                    size="sm"
                    variant="destructive"
                    onClick={() => {
                      // @ts-ignore
                      const person = billy.uncle
                    }}
                  >
                    Crash Undefined Variable 2
                  </Button>
                  <Button
                    size="sm"
                    variant="destructive"
                    onClick={() => {
                      someFunctionWithABigName()
                    }}
                  >
                    Crash Larger Stack
                  </Button>
                </ErrorBoundary>
              </div>
            )}

            {/* Top row actions (Feedback/Help/Docs) */}
            <div className="p-3 border-b border-gray-200 flex justify-end gap-2">
              {/* Feedback */}
              <Popover shouldBlockScroll={false}>
                <PopoverTrigger>
                  <Button
                    variant="outline"
                    className="text-gray-500 border border-gray-200"
                  >
                    Feedback
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-[340px] p-3">
                  <form
                    className="flex w-full flex-col gap-2"
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <Textarea
                      aria-label="Feedback"
                      name="feedback"
                      placeholder="Ideas, suggestions, or bugs are welcome. We'll respond promptly."
                      variant="faded"
                    />
                    <div className="flex w-full items-center justify-end gap-2 px-1 -mb-1">
                      <Icon
                        className="text-default-400"
                        icon="la:markdown"
                        width={20}
                      />
                      <p className="text-tiny text-default-400">
                        <Link
                          className="text-tiny text-default-500 flex flex-row items-center gap-1"
                          href="https://guides.github.com/features/mastering-markdown/"
                          rel="noreferrer"
                          target="_blank"
                        >
                          Markdown supported.
                          <Icon
                            className="[&>path]:stroke-[2px]"
                            icon="solar:arrow-right-up-linear"
                          />
                        </Link>
                      </p>
                    </div>
                    <Divider className="my-2" />
                    <div className="flex w-full items-center justify-between">
                      <FeedbackRating name="rating" />
                      <Button color="primary" size="sm" type="submit">
                        Submit
                      </Button>
                    </div>
                  </form>
                </PopoverContent>
              </Popover>

              {/* Help */}
              <a target="_blank" rel="noopener noreferrer" href="/help">
                <Button variant="light" className="text-gray-500">
                  Help
                </Button>
              </a>
              {/* Docs */}
              <a target="_blank" rel="noopener noreferrer" href="/docs">
                <Button variant="light" className="text-gray-500">
                  Docs
                </Button>
              </a>
            </div>

            {/* Main children content */}
            {children && (
              <div className="w-full flex justify-center overflow-y-auto">
                {React.cloneElement(children as React.ReactElement, {
                  isLoadingOrganizations: isFetching,
                  selectedOrganization,
                  organizations,
                })}
              </div>
            )}

            {/* Footer (optional) */}
            {/* <Footer /> */}
          </div>
        </div>
      </div>
    </Suspense>
  )
}
